import EpisodesArrayProp from "../Model/EpisodesArrayProp";
import EpisodePaginationComponent from "./Components/EpisodePaginationComponent";
import Socials from "../Data/socials.json";

function Episodes({inEpisodes}: EpisodesArrayProp) {

    if (inEpisodes === null) {
        return (
            <div className="page_content">

                <p>Click <a href={Socials.spotify} target="_blank" rel="noopener noreferrer">HERE</a> to see all episodes of the Wild Dogs podcast.</p>

            </div>
        );
    }

    return (
        <div className="page_content">

            <h2>
                ALL EPISODES
            </h2>
    
            <EpisodePaginationComponent 
                inEpisodes={inEpisodes} 
                count={5} 
                />

        </div>
    );
}

export default Episodes;
