import NewsData from "../../Model/NewsData";

function NewsArticleComponent({ inArticle }: {inArticle: NewsData}) {

    function getHumanReadableDate() {
        const date = new Date(inArticle.date * 1000);
        return date.toLocaleDateString("en-US");
    }

    return (
        <div className="news_article_item">
            <h1 className="news_article_item_text">
                {inArticle.title}
            </h1>
            <p className="news_article_item_text">
                {getHumanReadableDate()}
            </p>
        </div>
    );
}

export default NewsArticleComponent;
