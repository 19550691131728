import Socials from "../../Data/socials.json";

function onClick() {
    window.location.href = `mailto:${Socials["email"]}?subject=${encodeURIComponent("Hello Wild Dogs!")}`;
}

function EmailIcon() {
    return (
        <svg 
            className="social_icon"
            onClick={onClick}
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 448 512">
            <title>Email</title>
            <path d="M384 32H64C28.63 32 0 60.63 0 96v320c0 35.38 28.62 64 64 64h320c35.38 0 64-28.62 64-64V96C448 60.63 419.4 32 384 32zM384 336c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32V225.9l138.5 69.27C209.3 298.5 216.6 300.2 224 300.2s14.75-1.688 21.47-5.047L384 225.9V336zM384 190.1l-152.8 76.42c-4.5 2.25-9.812 2.25-14.31 0L64 190.1V176c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32V190.1z"></path>
        </svg>
    );
}

export default EmailIcon;

