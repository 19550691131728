import { useEffect, useState } from "react";
import NewsData from "../Model/NewsData";
import NewsArticleComponent from "./Components/NewsArticleComponent";

function News(props: any) {
    const [newsList, setNewsList] = useState<NewsData[] | null>(null);
    
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(
                    'http://api.wilddogspod.com/v1/news_list', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();

                let newsData: NewsData[] = [];
                result.data.forEach((news: any) => {
                    const currNews: NewsData = {
                        id: news.id,
                        title: news.title,
                        date: news.date
                    };

                    newsData.push(currNews);
                });

                newsData.sort((a, b) => b.date - a.date);
                setNewsList(newsData);
            } catch (err) {
                console.log(err);
            } 
        };

        if (!newsList) {
            fetchData();
        }

    }, [newsList]);

    function handleArticleClicked(id: string) {
        props.onArticleClicked(id);
    }

    if (newsList === null) {
        return (
            <div className="page_content">

                <h1>WILD DOG NEWS</h1>

                <h2>No news right now. Come back again soon!</h2>
            
            </div>
        );

    }

    return (
        <div className="page_content">

            <h1>WILD DOG NEWS</h1>

            <div className="news_list">
            {newsList && newsList.map((item) => (
                <div onClick={() => {handleArticleClicked(item.id)}}>
                    <NewsArticleComponent
                        key={item.id}
                        inArticle={item}/>
                </div>
            ))}
            </div>
        
        </div>
    );
}

export default News;
