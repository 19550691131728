function Footer() {
    const currYear = new Date().getFullYear();

    return (
        <footer>
            <p>© {currYear} by the Wild Dogs Podcast</p>
        </footer>
    );
}

export default Footer;
