import { marked } from "marked";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


function ArticlePage() {
    const { id } = useParams<{ id: string }>();
    const [article, setArticle] = useState<string>("");

    useEffect(() => {

        const fetchData = async () => {
            try {

                const response = await fetch(
                    'http://api.wilddogspod.com/v1/news/' + id, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();
                const htmlString = marked(result.data);

                setArticle(htmlString.toString());

            } catch (err) {
                console.log(err);
            } 
        };

        if (article === "") {
            fetchData();
        }

    }, [article, id]);

    if (article === "" || article === null) {
        return (
            <div className="page_content">
                <p>Failed to load article content. Come back again later.</p>
            </div>
        );
    }

    return (
        <div className="page_content">
            <div className="markdown-body " 
                dangerouslySetInnerHTML={{ __html: article }} />
        </div>
    );
}

export default ArticlePage;
