enum NavigationTabType {
    home,
    episodes,
    about,
    subscribe,
    news,
    undefined
}

export default NavigationTabType;
