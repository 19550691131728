import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import NavigationTabType from "./Model/NavigationTabType";
import EpisodeData from "./Model/EpisodeData";
import Header from './View/Header';
import Home from './View/Home';
import Episodes from './View/Episodes';
import About from './View/About';
import Subscribe from './View/Subscribe';
import Undefined from './View/Undefined';
import News from "./View/News";
import ArticlePage from "./View/ArticlePage";
import Footer from "./View/Footer";

function App() {
    const navigate = useNavigate();
    const [episodes, setEpisodes] = useState<EpisodeData[] | null>(null);

    useEffect(() => {

        const fetchEpisoeData = async () => {
            try {
                const episodesResponse = await fetch(
                    'http://api.wilddogspod.com/v1/episodes', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                });

                if (!episodesResponse.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await episodesResponse.json();

                let episodeData: EpisodeData[] = [];
                result.data.forEach((episode: any) => {
                    const currEpisode: EpisodeData = {
                        name: episode.name,
                        description: episode.description,
                        imageUrl: episode.images[0].url,
                        episodeUrl: episode.external_urls.spotify,
                        releaseData: new Date(episode.release_date).getTime()
                    };

                    episodeData.push(currEpisode);
                });

                episodeData.sort((a, b) => b.releaseData - a.releaseData);

                setEpisodes(episodeData);
            } catch (err) {
                console.log(err);
            } 
        };

        if (!episodes) {
            fetchEpisoeData();
        }

    }, [episodes]);


    function changedTab(navigationTab: NavigationTabType) {
        switch (navigationTab) {
            case NavigationTabType.home : {
                navigate("/");
                break;
            }
            case NavigationTabType.episodes : {
                navigate("/episodes");
                break;
            }
            case NavigationTabType.about : {
                navigate("/about");
                break;
            }
            case NavigationTabType.subscribe : {
                navigate("/subscribe");
                break;
            }
            case NavigationTabType.news : {
                navigate("/news");
                break;
            }
            default : {
                navigate("/");
                break;
            }
        }
    }

    function articleSelected(id: string) {
        navigate("/news/" + id);
    }

    return (
        <div>

            <Header onTabChanged={changedTab} />

            <Routes>

                <Route path="/" element={<Home inEpisodes={episodes} />} />
                <Route path="/episodes" element={<Episodes inEpisodes={episodes} />} />
                <Route path="/about" element={<About />} />
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/news" element={<News onArticleClicked={articleSelected} />} />
                <Route path="/news/:id" element={<ArticlePage />} />
                <Route path="*" element={<Undefined />} />

            </Routes>

            <Footer />
        </div>

    );
}

export default App;
