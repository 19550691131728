import { Link } from 'react-router-dom';
import EpisodeData from "../Model/EpisodeData";
import EpisodesArrayProp from "../Model/EpisodesArrayProp";
import EpisodeComponent from "./Components/EpisodeComponent";
import DiscordIcon from './Icons/DiscordIcon';
import EmailIcon from './Icons/EmailIcon';
import InstagramIcon from './Icons/InstagramIcon';
import SpotifyIcon from './Icons/SpotifyIcon';
import TwitterIcon from './Icons/TwitterIcon';
import YoutubeIcon from './Icons/YoutubeIcon';


function Home({inEpisodes}: EpisodesArrayProp) {

    function getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function getFeaturedEpisodeUI() {
        if (inEpisodes) {
            const randomEpisode: EpisodeData = inEpisodes[getRandomInt(0, inEpisodes.length - 1)];
            return (
                <EpisodeComponent
                key={randomEpisode.name}
                inEpisode={randomEpisode}
                />
            );
        }

        return null;
    }

    function getEpisodeUI() {
        if (inEpisodes) {
            return inEpisodes.slice(0, 5).map((episode) => (
                <EpisodeComponent
                key={episode.name}
                inEpisode={episode}
                />
            ));
        }
    
        return null;
    }


    // Failed to get episodes.
    if (inEpisodes === null) {

        return (
            <div>

                <img
                    alt="home banner"
                    className="home_banner" 
                    src="images/WildDogsBanner.png"/> 

                <div className="page_content">

                    <p className="home_text">
                        Just two wild dogs (Leland and Lukas) talking about all things related to Magic the Gathering, some things kinda-sorta related to Magic the Gathering, and even things completely unrelated to Magic the Gathering.
                    </p>

                    <div className="home_links">
                        <SpotifyIcon />
                        <YoutubeIcon />
                        <TwitterIcon />
                        <InstagramIcon />
                        <DiscordIcon />
                        <EmailIcon />
                    </div>
                </div>

            </div>
        );
    }

    return (
        <div>

            <img
                alt="home banner"
                className="home_banner" 
                src="images/WildDogsBanner.png"/> 

            <div className="page_content">

                <p className="home_text">
                    Just two wild dogs (Leland and Lukas) talking about all things related to Magic the Gathering, some things kinda-sorta related to Magic the Gathering, and even things completely unrelated to Magic the Gathering.
                </p>


                <h2 className="home_text">
                    FEATURED EPISODE
                </h2>
                <ul>
                    {getFeaturedEpisodeUI()}
                </ul>


                <h2 className="home_text">
                    LATEST EPISODES
                </h2>

                <ul>
                    {getEpisodeUI()}
                </ul>

                <p>
                    Click{' '}
                    <Link 
                        to="/episodes" 
                        rel="noopener noreferrer">
                        HERE
                    </Link>{' '}
                    to view all episodes.
                </p>

                <div className="home_links">
                    <SpotifyIcon />
                    <YoutubeIcon />
                    <TwitterIcon />
                    <InstagramIcon />
                    <DiscordIcon />
                    <EmailIcon />
                </div>
            </div>

        </div>
    );
}

export default Home;
