import NavigationTabType from "../Model/NavigationTabType";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function Header(props: any) {
    const [selectedTab, setSelectedTab] = useState<NavigationTabType>(NavigationTabType.home);
    const location = useLocation();

    function handleClick(navigationTab: NavigationTabType) {
        // Make sure that we dont continually load the home and news tabs.
        if (navigationTab === NavigationTabType.news && location.pathname === "/news") {
            return;
        }
        if (navigationTab === NavigationTabType.home && location.pathname === "/") {
            return;
        }

        setSelectedTab(navigationTab);
        props.onTabChanged(navigationTab);
    }

    return (
        <header>

            <img 
                className="header_icon" 
                src="images/WildDogsIcon.png"
                alt="header icon"
                onClick={() => {handleClick(NavigationTabType.home)}}
                /> 

            <div>

                <form>
                    <label className="tab tab_home">
                        <input 
                        type="radio" 
                        value={NavigationTabType.home} 
                        checked={selectedTab === NavigationTabType.home} 
                        onChange={() => {handleClick(NavigationTabType.home)} } 
                        onClick={() => {handleClick(NavigationTabType.home)} } 
                        />
                        HOME 
                    </label>
                    
                    <label className="tab">
                        <input 
                        type="radio" 
                        value={NavigationTabType.episodes} 
                        checked={selectedTab === NavigationTabType.episodes} 
                        onChange={() => {handleClick(NavigationTabType.episodes)} } />
                        EPISODES 
                    </label>
                    
                    <label className="tab">
                        <input
                        type="radio" 
                        value={NavigationTabType.about} 
                        checked={selectedTab === NavigationTabType.about} 
                        onChange={() => {handleClick(NavigationTabType.about)}} />
                        ABOUT 
                    </label>
                    
                    <label className="tab">
                        <input
                        type="radio" 
                        value={NavigationTabType.news} 
                        checked={selectedTab === NavigationTabType.news} 
                        onChange={() => {handleClick(NavigationTabType.news)}} 
                        onClick={() => {handleClick(NavigationTabType.news)}} 
                        />
                        NEWS 
                    </label>
                    
                    <label className="tab">
                        <input
                        type="radio" 
                        value={NavigationTabType.subscribe} 
                        checked={selectedTab === NavigationTabType.subscribe} 
                        onChange={() => {handleClick(NavigationTabType.subscribe)}} />
                        SUBSCRIBE 
                    </label>
                
                </form>
            </div>

        </header>
    );
}

export default Header;
