import EpisodeData from "../../Model/EpisodeData";

function EpisodeComponent({ inEpisode }: {inEpisode: EpisodeData}) {

    return (
        <div className="episode_component">
            <div className="episode_component_align_items">
                
                <a href={inEpisode?.episodeUrl} 
                    target="_blank" 
                    rel="noopener noreferrer">
                    <img 
                        className="episode_component_image"
                        alt={inEpisode?.name}
                        src={inEpisode?.imageUrl} /> 
                </a>

                <div className="episode_title_description">
                    <a href={inEpisode?.episodeUrl} 
                        className="episode_title_text" 
                        target="_blank" 
                        rel="noopener noreferrer">
                        {inEpisode?.name}
                    </a>

                    <p className="episode_component_multiline_truncated_text">{inEpisode?.description}</p> 
                </div>

            </div>
        </div>
    );
}

export default EpisodeComponent;
