import DiscordIcon from "./Icons/DiscordIcon";
import EmailIcon from "./Icons/EmailIcon";
import InstagramIcon from "./Icons/InstagramIcon";
import MoxfieldIcon from "./Icons/MoxfieldIcon";
import SpotifyIcon from "./Icons/SpotifyIcon";
import TwitterIcon from "./Icons/TwitterIcon";
import YoutubeIcon from "./Icons/YoutubeIcon";

function Subscribe() {
    return (
        <div className="page_content">
            <h2>
                WHERE TO LISTEN 
            </h2>
            <div>
                <SpotifyIcon />
                <YoutubeIcon />
            </div>

            <h2>
                WHERE TO FOLLOW
            </h2>
            <div>
                <TwitterIcon />
                <InstagramIcon />
                <DiscordIcon />
                <EmailIcon />
                <MoxfieldIcon />
            </div>


        </div>
    );
}

export default Subscribe;
