import { useState } from "react";
import EpisodePaginationProp from "../../Model/EpisodePaginationProp";
import EpisodeComponent from "./EpisodeComponent";

function EpisodePaginationComponent( {inEpisodes, count}: EpisodePaginationProp ) {

    const [index, setIndex] = useState<number>(0);
    const [showNext, setShowNext] = useState<boolean>(true);
    const [showBack, setShowBack] = useState<boolean>(false);


    function getDisplayLabelText() {
        if (inEpisodes) {
            const total = inEpisodes.length;
            const firstShown = index + 1;
            const lastShown = (index + count) < total -1 ? index + count : total;
            return (
                <div className="home_text">
                    <p>
                        Displaying <strong>{firstShown}</strong> -  <strong>{lastShown}</strong> of <strong>{total}</strong> total
                    </p>
                </div>
            );

        }

        return null;
    }

    function getEpisodesUI() {
        if (inEpisodes) {
            return (
                <ul>
                {
                    inEpisodes.slice(index, index + count).map((episode) => (
                        <EpisodeComponent
                        key={episode.name}
                        inEpisode={episode}
                        />

                    ))
                }
                </ul>);
        }

        return null;
    }

    function updateButtonVisibility(nextIndex: number) {
        if (inEpisodes) {
            setShowBack(nextIndex !== 0);
            setShowNext(nextIndex < inEpisodes.length && nextIndex + count < inEpisodes.length);
        }
    }
    
    function onBackClicked() {
        const nextIndex = index - count >= 0 ? index - count : 0;

        setIndex(nextIndex);
        updateButtonVisibility(nextIndex);

        window.scrollTo(0, 0);
    }

    function onNextClicked() {
        if (inEpisodes) {
            const episodeCount = inEpisodes.length;
            const nextIndex = index + count < episodeCount ? index + count : inEpisodes.length - 1;

            setIndex(nextIndex);
            updateButtonVisibility(nextIndex);
        }

        window.scrollTo(0, 0);
    }

    return (
        <div >
            <div>
                {getDisplayLabelText()}
            </div>
            <div>
                {getEpisodesUI()}
            </div>
            <div className="episode_navigation_buttons">
                    <button
                        onClick={onBackClicked}
                        disabled={!showBack}>
                        Back
                    </button>
                
                    <button 
                        onClick={onNextClicked}
                        disabled={!showNext}>
                        Next
                    </button>
            </div>
        </div>
    );
}

export default EpisodePaginationComponent;
